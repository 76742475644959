h1.text-title{
	font-size: 48px;
	font-weight: bold;
}
h2.text-title{
	font-size: 36px;
}
span.result-title{
	font-size: 48px;
	font-weight: bold;
	line-height: 80px;
    vertical-align: middle;
}
.nav-link.navtabinactive {
	background-color: #ced4da73 !important;
	border-color: #ced4da  !important;
}
.action-button{
	line-height: 30px !important;
	font-weight: bold !important;
	font-size: 26px !important;
	height: 80px;
	// border-radius: 30px;
	min-width: 250px;
	margin: 5px;
}
.result-stat-text{
	font-size: "36px";
	height: '80px';
	border-radius: '30px';
	min-width: '150px';
	margin: '5px';
	background-color: "#556ee6";
	border-color: "#556ee6";
}
.result-simple-body{
    z-index: 3;
}
div.tagline { 
	box-sizing: border-box;
	color: #9b9faa;
	font-size: 1.25em;
	margin-bottom: 20px;
	text-align: center;
}

div.result-title{
    font-size: 1.25em;
}


svg.result-background { 
	bottom: 0px;
	box-sizing: border-box;
	color: #51596a;
	display: block;
	font-size: 18px;
	height: 560px;
	left: -80%;
	overflow: hidden;
	position: absolute;
	right: 0px;
	top: 61.359px;
	width: 220%;
	z-index: -1;
}

polygon.result-c1 { 
	box-sizing: border-box;
	color: #51596a;
	opacity: 0.5;
	/* fill: #d8a537; */
	font-size: 18px;
}

@media (min-width: 460px) { 
	svg.result-background { 
		height: 600px;
	}

}

@media (min-width: 768px) { 
	svg.result-background { 
		left: -40%;
		width: 160%;
	}

}

@media (min-width: 992px) { 
	svg.result-background { 
		height: unset;
		left: 0px;
		width: 100%;
	}

}
