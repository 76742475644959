
header { 
	background-color: #94BC3B;
	bottom: 0px;
	box-sizing: border-box;
	color: #51596a;
	font-size: 18px;
	left: 0px;
	// padding: 15px;
	position: relative;
	right: 0px;
	top: 0px;
	text-align: center;
}

div.trademark { 
	box-sizing: border-box;
	color: #f5f5f5;
	font-size: 0.9em;
	text-align: center;
}

@media (min-width: 400px) { 
	// header { 
	// 	padding: 15px 30px;
	// }

}

@media (min-width: 768px) and (min-height: 900px) { 
	// header { 
	// 	padding: 20px 30px;
	// }

}

@media (min-width: 992px) { 
	header { 
		padding: 25px 0px;
	}

	div.trademark { 
		font-size: 1.1em;
	}

}

@media (min-width: 992px) and (min-height: 900px) { 
	// header { 
	// 	padding: 45px 30px;
	// }

}


section.tips { 
	align-items: center;
	background-color: #94BC3B;
	bottom: 0px;
	box-sizing: border-box;
	color: #51596a;
	display: flex;
	flex-direction: column;
	font-size: 18px;
	justify-content: space-between;
	left: 0px;
	outline: none;
	overflow: hidden;
	// padding: 15px;
	position: relative;
	right: 0px;
	top: 0px;
}

div.tip-highlight.first { 
	background-color: #4298b4;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border-radius: 10px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	bottom: 14px;
	box-sizing: border-box;
	color: #51596a;
	display: none;
	font-size: 18px;
	// height: 240px;
	left: calc(50% - 470px);
	mix-blend-mode: hue;
	outline: none;
	position: absolute;
	right: 999px;
	top: 70px;
	width: 370px;
	z-index: 3;
}

div.tip-highlight.second { 
	background-color: #e4ae3a;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border-radius: 10px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	bottom: 14px;
	box-sizing: border-box;
	color: #51596a;
	display: none;
	font-size: 18px;
	// height: 240px;
	left: calc(50% - 150px);
	mix-blend-mode: hue;
	outline: none;
	position: absolute;
	right: 629px;
	top: 70px;
	width: 370px;
	z-index: 3;
}

div.tip-highlight.third { 
	background-color: #583f64;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border-radius: 10px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	bottom: 14px;
	box-sizing: border-box;
	color: #51596a;
	display: none;
	font-size: 18px;
	// height: 240px;
	left: calc(50% + 170px);
	mix-blend-mode: hue;
	outline: none;
	position: absolute;
	right: 259px;
	top: 70px;
	width: 370px;
	z-index: 3;
}

div.tip { 
	align-items: center;
	background-color: rgba(255, 255, 255, 0.8);
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border-radius: 10px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	bottom: 0px;
	box-sizing: border-box;
	color: #51596a;
	display: flex;
	flex: 0 0 100%;
	flex-direction: row;
	font-size: 18px;
	justify-content: flex-start;
	left: 0px;
	margin-bottom: 10px;
	max-width: 550px;
	outline: none;
	padding: 10px;
	position: relative;
	right: 0px;
	top: 0px;
	width: 100%;
	z-index: 4;
}

// img { 
// 	box-sizing: border-box;
// 	color: #51596a;
// 	display: block;
// 	font-size: 18px;
// 	outline: none;
// 	width: 30px;
// }

div.text { 
	box-sizing: border-box;
	color: #51596a;
	font-size: 18px;
	max-width: 100%;
	outline: none;
	padding-left: 10px;
}

div.title { 
	box-sizing: border-box;
	color: #576071;
	display: none;
	font-size: 0.8em;
	font-weight: 600;
	margin-bottom: 5px;
	outline: none;
	text-align: center;
}

div.subtitle { 
	box-sizing: border-box;
	color: #51596a;
	font-size: 0.65em;
	line-height: 1.5;
	outline: none;
	text-align: center;
}

:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) { 
	align-items: center;
	background-color: rgba(255, 255, 255, 0.8);
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border-radius: 10px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	bottom: 0px;
	box-sizing: border-box;
	color: #51596a;
	display: flex;
	flex: 0 0 100%;
	flex-direction: row;
	font-size: 18px;
	justify-content: flex-start;
	left: 0px;
	margin-bottom: 10px;
	max-width: 550px;
	outline: none;
	padding: 10px;
	position: relative;
	right: 0px;
	top: 0px;
	width: 100%;
	z-index: 4;
}

:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) > :nth-child(2) { 
	box-sizing: border-box;
	color: #51596a;
	font-size: 18px;
	max-width: 100%;
	outline: none;
	padding-left: 10px;
}

:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) > :nth-child(2) > :nth-child(1) { 
	box-sizing: border-box;
	color: #576071;
	display: none;
	font-size: 0.8em;
	font-weight: 600;
	margin-bottom: 5px;
	outline: none;
	text-align: center;
}

:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) > :nth-child(2) > :nth-child(2) { 
	box-sizing: border-box;
	color: #51596a;
	font-size: 0.65em;
	line-height: 1.5;
	outline: none;
	text-align: center;
}

div.tip.last { 
	align-items: center;
	background-color: rgba(255, 255, 255, 0.8);
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border-radius: 10px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	bottom: 0px;
	box-sizing: border-box;
	color: #51596a;
	display: flex;
	flex: 0 0 100%;
	flex-direction: row;
	font-size: 18px;
	justify-content: flex-start;
	left: 0px;
	margin-bottom: 0px;
	max-width: 550px;
	outline: none;
	padding: 10px;
	position: relative;
	right: 0px;
	top: 0px;
	width: 100%;
	z-index: 4;
}

.last .text { 
	box-sizing: border-box;
	color: #51596a;
	font-size: 18px;
	max-width: 100%;
	outline: none;
	padding-left: 10px;
}

.last .title { 
	box-sizing: border-box;
	color: #576071;
	display: none;
	font-size: 0.8em;
	font-weight: 600;
	margin-bottom: 5px;
	outline: none;
	text-align: center;
}

.last .subtitle { 
	box-sizing: border-box;
	color: #51596a;
	font-size: 0.65em;
	line-height: 1.5;
	outline: none;
	text-align: center;
}

div.shape { 
	background-color: #94BC3B;
	bottom: 0px;
	box-sizing: border-box;
	clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 15px), 81% 100%, 46% calc(100% - 32px), 19% 100%, 0px calc(100% - 15px));
	color: #51596a;
	display: none;
	font-size: 18px;
	height: 230px;
	inset: -1px 0px 0px;
	left: 0px;
	outline: none;
	position: absolute;
	right: 0px;
	top: -1px;
	width: 100%;
	z-index: 2;
}

div.shadow { 
	background-color: #f9f9f9;
	bottom: 0px;
	box-sizing: border-box;
	color: #51596a;
	display: none;
	font-size: 18px;
	inset: 95px 0px 0px;
	left: 0px;
	outline: none;
	position: absolute;
	right: 0px;
	top: 95px;
	z-index: 1;
}

@media (min-width: 460px) { 
	section.tips { 
		padding-top: 20px;
	}

	div.tip { 
		padding: 15px;
	}

	// img { 
	// 	width: 40px;
	// }

	div.text { 
		padding-left: 15px;
	}

	div.title { 
		display: block;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) { 
		padding: 15px;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) > :nth-child(2) { 
		padding-left: 15px;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) > :nth-child(2) > :nth-child(1) { 
		display: block;
	}

	div.tip.last { 
		padding: 15px;
	}

	.last .text { 
		padding-left: 15px;
	}

	.last .title { 
		display: block;
	}

}

@media (min-width: 768px) { 
	section.tips { 
		align-items: stretch;
		clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 70% calc(100% - 15px), 25% 100%, 0px calc(100% - 10px));
		flex-direction: row;
		justify-content: center;
		padding: 20px 10px 30px;
	}

	div.tip { 
		flex: 0 0 calc(33.3333% - 20px);
		flex-direction: column;
		margin: 10px 10px 0px;
		max-width: unset;
		padding: 25px 0px;
		width: calc(33.3333% - 20px);
	}

	// img { 
	// 	margin-bottom: 15px;
	// 	width: 75px;
	// }

	div.text { 
		padding: 0px 15px;
	}

	div.title { 
		font-size: 1em;
		margin-bottom: 10px;
		text-align: center;
	}

	div.subtitle { 
		font-size: 0.8em;
		line-height: 1.7;
		text-align: center;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) { 
		flex: 0 0 calc(33.3333% - 20px);
		flex-direction: column;
		margin: 10px 10px 0px;
		max-width: unset;
		padding: 25px 0px;
		width: calc(33.3333% - 20px);
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) > :nth-child(2) { 
		padding: 0px 15px;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) > :nth-child(2) > :nth-child(1) { 
		font-size: 1em;
		margin-bottom: 10px;
		text-align: center;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) > :nth-child(2) > :nth-child(2) { 
		font-size: 0.8em;
		line-height: 1.7;
		text-align: center;
	}

	div.tip.last { 
		flex: 0 0 calc(33.3333% - 20px);
		flex-direction: column;
		margin: 10px 10px 0px;
		max-width: unset;
		padding: 25px 0px;
		width: calc(33.3333% - 20px);
	}

	.last .text { 
		padding: 0px 15px;
	}

	.last .title { 
		font-size: 1em;
		margin-bottom: 10px;
		text-align: center;
	}

	.last .subtitle { 
		font-size: 0.8em;
		line-height: 1.7;
		text-align: center;
	}

}

@media (min-width: 992px) { 
	section.tips { 
		background-color: #ffffff;
		clip-path: none;
		overflow: unset;
		padding: 0px 0px 30px;
	}

	div.tip-highlight.first { 
		display: block;
		top: 10px;
		width: 300px;
	}

	div.tip-highlight.second { 
		display: block;
		top: 10px;
		width: 300px;
	}

	div.tip-highlight.third { 
		display: block;
		top: 10px;
		width: 300px;
	}

	div.tip { 
		flex: 0 0 300px;
		margin: 10px 10px 0px;
		padding: 20px 0px;
		width: 300px;
	}

	// img { 
	// 	margin-bottom: 30px;
	// 	width: 60px;
	// }

	div.text { 
		padding: 0px 20px;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) { 
		flex: 0 0 300px;
		margin: 10px 10px 0px;
		padding: 20px 0px;
		width: 300px;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) > :nth-child(2) { 
		padding: 0px 20px;
	}

	div.tip.last { 
		flex: 0 0 300px;
		margin: 10px 10px 0px;
		padding: 20px 0px;
		width: 300px;
	}

	.last .text { 
		padding: 0px 20px;
	}

	div.shape { 
		clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 20px), calc(50% + 320px) 100%, 35% calc(100% - 15px), calc(50% - 310px) 100%, 0px calc(100% - 15px));
		display: block;
		height: 110px;
	}

	div.shadow { 
		bottom: 0px;
		box-shadow: none;
		display: block;
	}

}

@media (min-width: 992px) and (min-height: 900px) { 
	section.tips { 
		padding: 20px 0px 30px;
	}

	div.tip-highlight.first { 
		height: 260px;
		top: 30px;
	}

	div.tip-highlight.second { 
		height: 260px;
		top: 30px;
	}

	div.tip-highlight.third { 
		height: 260px;
		top: 30px;
	}

	div.tip { 
		padding: 25px 0px;
	}

	// img { 
	// 	width: 75px;
	// }

	div.text { 
		padding: 0px 30px;
	}

	div.title { 
		font-size: 1.1em;
		margin-bottom: 10px;
	}

	div.subtitle { 
		font-size: 0.9em;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) { 
		padding: 25px 0px;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) > :nth-child(2) { 
		padding: 0px 30px;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) > :nth-child(2) > :nth-child(1) { 
		font-size: 1.1em;
		margin-bottom: 10px;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) > :nth-child(2) > :nth-child(2) { 
		font-size: 0.9em;
	}

	div.tip.last { 
		padding: 25px 0px;
	}

	.last .text { 
		padding: 0px 30px;
	}

	.last .title { 
		font-size: 1.1em;
		margin-bottom: 10px;
	}

	.last .subtitle { 
		font-size: 0.9em;
	}

	div.shape { 
		clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 30px), calc(50% + 320px) 100%, 40% calc(100% - 40px), calc(50% - 310px) 100%, 0px calc(100% - 30px));
		height: 140px;
	}

}

@media (min-width: 1200px) { 
	div.tip-highlight.first { 
		left: calc(50% - 545px);
		width: 350px;
	}

	div.tip-highlight.second { 
		left: calc(50% - 175px);
		width: 350px;
	}

	div.tip-highlight.third { 
		left: calc(50% + 195px);
		width: 350px;
	}

	div.tip { 
		flex: 0 0 350px;
		width: 350px;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) { 
		flex: 0 0 350px;
		width: 350px;
	}

	div.tip.last { 
		flex: 0 0 350px;
		width: 350px;
	}

	div.shape { 
		clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 20px), calc(50% + 320px) 100%, 47% calc(100% - 15px), calc(50% - 310px) 100%, 0px calc(100% - 15px));
	}

}

@media (min-width: 1200px) and (min-height: 900px) { 
	// img { 
	// 	margin-bottom: 35px;
	// 	width: 90px;
	// }

	div.title { 
		font-size: 1.2em;
		margin-bottom: 15px;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) > :nth-child(2) > :nth-child(1) { 
		font-size: 1.2em;
		margin-bottom: 15px;
	}

	.last .title { 
		font-size: 1.2em;
		margin-bottom: 15px;
	}

	div.shape { 
		clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 30px), calc(50% + 370px) 100%, 47% calc(100% - 27px), calc(50% - 370px) 100%, 0px calc(100% - 30px));
		height: 155px;
	}

}

@media (min-width: 850px) { 
	div.text { 
		padding: 0px 30px;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) > :nth-child(2) { 
		padding: 0px 30px;
	}

	.last .text { 
		padding: 0px 30px;
	}

}

@media (min-width: 1400px) { 
	div.text { 
		padding: 0px 20px;
	}

	div.title { 
		font-size: 1.2em;
	}

	div.subtitle { 
		font-size: 0.95em;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) > :nth-child(2) { 
		padding: 0px 20px;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) > :nth-child(2) > :nth-child(1) { 
		font-size: 1.2em;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) > :nth-child(2) > :nth-child(2) { 
		font-size: 0.95em;
	}

	.last .text { 
		padding: 0px 20px;
	}

	.last .title { 
		font-size: 1.2em;
	}

	.last .subtitle { 
		font-size: 0.95em;
	}

}

@media (min-width: 400px) { 
	div.subtitle { 
		font-size: 0.75em;
	}

	:root > :nth-child(2) > :nth-child(2) > :nth-child(3) > :nth-child(2) > :nth-child(5) > :nth-child(2) > :nth-child(2) { 
		font-size: 0.75em;
	}

	.last .subtitle { 
		font-size: 0.75em;
	}

}

