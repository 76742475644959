.body-tab-result{
    font-size: 20px;
    padding: 20px;
}

.tab-content.body-tab-content{
    border-bottom: 1px solid rgb(206, 212, 218);
    border-left: 1px solid rgb(206, 212, 218);
    border-right: 1px solid rgb(206, 212, 218);
    background-color: #fff;
}
